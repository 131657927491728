<template>
  <b-container>
    <calculator-detail title="Dana Pensiun" description="">
      <div slot="calculator" class="mt-4">
        <b-form-group label-cols-sm="4" label="Usia saat ini">
          <b-input-group type="number">
            <b-form-input v-model="currentAge"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="4" label="Usia Pensiun">
          <b-input-group type="number">
            <b-form-input v-model="retirementAge"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label-cols-sm="4"
          label="Prediksi lama hidup setelah pensiun"
        >
          <b-input-group>
            <b-form-input v-model="lifeExpectancy"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="4" label="Biaya hidup per bulan">
          <b-input-group prepend="Rp" type="number">
            <b-form-input v-model="monthlyExpenses" v-currency></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="4" label="Inflasi per tahun">
          <b-input-group append="%" type="number">
            <b-form-input v-model="inflationRate"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="4" label="Biaya hidup per tahun">
          <b-input-group prepend="Rp" type="number">
            <b-form-input v-model="annualExpenses" disabled></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="4" label="Biaya hidup saat pensiun">
          <b-input-group prepend="Rp" type="number">
            <b-form-input
              v-model="retirementLivingCost"
              disabled
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="4" label="Inflasi setelah pensiun">
          <b-input-group append="%" type="number">
            <b-form-input v-model="inflationAfterRetirement"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="4" label="Imbal hasil setelah pensiun">
          <b-input-group append="%" type="number">
            <b-form-input v-model="returnAfterRetirement"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label-cols-sm="4"
          label="Dana pensiun yang perlu dipersiapkan"
        >
          <b-input-group prepend="Rp" type="number">
            <b-form-input v-model="requiredFunds" disabled></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-button @click="calculate" variant="danger">Hitung</b-button>
      </div>
    </calculator-detail>
  </b-container>
</template>

<script>
import CalculatorDetail from '../../components/CalculatorDetail.vue'
import * as formulajs from '@formulajs/formulajs'

export default {
  name: 'DanaPensiun',
  components: {
    CalculatorDetail,
  },
  data() {
    return {
      currentAge: '',
      retirementAge: '',
      lifeExpectancy: '',
      monthlyExpenses: '',
      inflationRate: '',
      annualExpenses: '',
      retirementLivingCost: '',
      inflationAfterRetirement: '',
      returnAfterRetirement: '',
      requiredFunds: '',
      mode: 0,
    }
  },
  computed: {
    r() {
      return parseFloat(this.inflationRate) / 100
    },
    n() {
      return parseFloat(this.retirementAge) - parseFloat(this.currentAge)
    },
    pv() {
      return this.toZeroMinus(this.unformatCurrency(this.monthlyExpenses) * 12)
    },
  },
  methods: {
    calculate() {
      const annualExpenses = this.pv
      const retirementLivingCost = this.calculateRetirementLivingCost()

      this.annualExpenses = this.formatToCurrency(annualExpenses)
      this.retirementLivingCost = this.formatToCurrency(retirementLivingCost)

      const requiredFunds = this.calculateRequiredFunds(retirementLivingCost)
      this.requiredFunds = this.formatToCurrency(requiredFunds)
    },
    calculateRetirementLivingCost() {
      const fv = formulajs.FV(this.r, this.n, 0, this.pv, this.mode) * -1
      return fv.toFixed(2)
    },
    calculateRequiredFunds(pmt) {
      const r =
        (parseFloat(this.returnAfterRetirement) -
          parseFloat(this.inflationAfterRetirement)) /
        100

      const n = parseFloat(this.lifeExpectancy)
      const pv = formulajs.PV(r, n, pmt, 0, this.mode) * -1

      return pv.toFixed(2)
    },
  },
}
</script>
